import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				CORESTRENGTH HUB
			</title>
			<meta name={"description"} content={"Ми більше, ніж тренажерний зал – ми спільнота, яка зосереджена на тому, щоб допомогти вам розкрити свій потенціал і досягти здоров’я на всіх рівнях."} />
			<meta property={"og:title"} content={"CORESTRENGTH HUB"} />
			<meta property={"og:description"} content={"Ми більше, ніж тренажерний зал – ми спільнота, яка зосереджена на тому, щоб допомогти вам розкрити свій потенціал і досягти здоров’я на всіх рівнях."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-2.jpg?v=2024-05-31T12:21:21.980Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-2.jpg?v=2024-05-31T12:21:21.980Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-2.jpg?v=2024-05-31T12:21:21.980Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-2.jpg?v=2024-05-31T12:21:21.980Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-2.jpg?v=2024-05-31T12:21:21.980Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-2.jpg?v=2024-05-31T12:21:21.980Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-2.jpg?v=2024-05-31T12:21:21.980Z"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6620d5f56bbd20002369dde3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});